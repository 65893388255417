<template>
  <div>
    <v-toolbar flat class="mb-2">
      <v-btn-toggle dense v-model="filterType" class="mr-4" mandatory
        ><v-btn text>Institutionen</v-btn
        ><v-btn text>Familien</v-btn></v-btn-toggle
      ><var></var>
      <v-btn-toggle dense v-model="filterSpecial" mandatory>
        <v-btn text>aktive</v-btn><v-btn text>inaktive</v-btn
        ><v-btn text>ignorierte</v-btn>
      </v-btn-toggle>
      <v-spacer />
      <v-text-field
        single-line
        dense
        hide-details
        label="Suche"
        v-model="search"
        append-icon="mdi-magnify"
        clearable
      ></v-text-field>
      <ReportButton
        text
        outlined
        color="info"
        class="ml-4"
        :parameters="{
          type: filterType == 1 ? 'families' : 'institutions',
        }"
        resource="report/placementaddresslabels"
        >Etiketten</ReportButton
      >

      <v-btn
        fab
        small
        elevation="0"
        color="success"
        class="ml-4"
        :to="{ name: 'PlacementInstitutionEdit', params: { id: 0 } }"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </v-toolbar>
    <v-card>
      <v-system-bar>
        {{ title }}
      </v-system-bar>
      <v-data-table
        :loading="loading"
        :items="itemsFiltered"
        :headers="headers"
        @click:row="showDetails"
        disable-filtering
        :custom-sort="sortInstitutions"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:item.comments="{ item }">
          <v-tooltip top v-if="item.comments">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-comment </v-icon>
            </template>
            <span>{{ item.comments }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.institution="{ item }">
          <InstitutionItem :value="item"></InstitutionItem>
        </template>
        <template v-slot:item.icons="{ item }">
          <v-tooltip top v-if="item.privat && item.new">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="warning">
                mdi-account
              </v-icon>
            </template>
            <span>neuer privater Eintrag</span>
          </v-tooltip>
          <v-tooltip top v-if="item.privat && !item.new">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-account </v-icon>
            </template>
            <span
              >privater Eintrag ({{ item.placementCount }}
              {{ item.placementCount == 1 ? "Besuch" : "Besuche" }} )</span
            >
          </v-tooltip>
        </template>
        <template v-slot:item.ignoreCount="{ item }">
          <v-tooltip top v-if="item.ignoreCount && item.ignoreCount > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-chip color="error" small v-bind="attrs" v-on="on">{{
                item.ignoreCount
              }}</v-chip>
            </template>
            <span>{{ item.ignoreCount }}x ignoriert</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <router-view @updated="fetchData" />
  </div>
</template>

<script>
import InstitutionItem from "./InstitutionItem.vue";
import ReportButton from "@/components/ReportButton.vue";

export default {
  components: { InstitutionItem, ReportButton },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "",
          value: "comments",
          sortable: false,
        },
        {
          text: "Institution",
          value: "institution",
          sortable: false,
        },
        {
          text: "",
          value: "icons",
          sortable: false,
        },
        {
          text: "",
          value: "ignoreCount",
          sortable: false,
        },
      ],
      items: [],
      filterType: 0,
      filterSpecial: 0,
      search: "",
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          ((this.filterType == 0 && el.instName) ||
            (this.filterType == 1 && !el.instName)) &&
          ((this.filterSpecial == 0 && el.active) ||
            (this.filterSpecial == 1 && !el.active) ||
            (this.filterSpecial == 2 && el.active && el.ignoreCount > 2)) &&
          (!this.search ||
            (el.instName &&
              el.instName.toLowerCase().includes(this.search.toLowerCase())) ||
            (el.firstName1 &&
              el.firstName1
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (el.lastName1 &&
              el.lastName1.toLowerCase().includes(this.search.toLowerCase())) ||
            (el.firstName2 &&
              el.firstName2
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (el.lastName2 &&
              el.lastName2.toLowerCase().includes(this.search.toLowerCase())) ||
            (el.town &&
              el.town.toLowerCase().includes(this.search.toLowerCase())))
      );
    },
    title() {
      let result = "";
      switch (this.filterSpecial) {
        case 0:
          result += "aktive";
          break;
        case 1:
          result += "inaktive";
          break;
        case 2:
          result += "ignorierte";
          break;
        default:
        // code block
      }
      result += " ";
      result += this.filterType == 1 ? "Familien" : "Institutionen";
      return result;
    },
  },
  methods: {
    showDetails(item) {
      this.$router.push({
        name: "PlacementInstitutionDetail",
        params: { id: item.id },
      });
    },
    sortInstitutions(items) {
      if (this.filterSpecial == 2) {
        return items.sort((a, b) => {
          const strA = `${100 - a.ignoreCount}${a.instName ? a.instName : ""}${
            a.lastName1 ? a.lastName1 : ""
          }${a.lastName2 ? a.lastName2 : ""}`;
          const strB = `${100 - b.ignoreCount}${b.instName ? b.instName : ""}${
            b.lastName1 ? b.lastName1 : ""
          }${b.lastName2 ? b.lastName2 : ""}`;
          return strA.localeCompare(strB, "de-CH");
        });
      }

      return items.sort((a, b) => {
        const strA = `${a.instName ? a.instName : ""}${
          a.lastName1 ? a.lastName1 : ""
        }${a.lastName2 ? a.lastName2 : ""}`;
        const strB = `${b.instName ? b.instName : ""}${
          b.lastName1 ? b.lastName1 : ""
        }${b.lastName2 ? b.lastName2 : ""}`;
        return strA.localeCompare(strB, "de-CH");
      });
    },
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "placement/institution",
      });

      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
